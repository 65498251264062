import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTypes(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/product_types/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchType(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/product_types/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addType(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/product_types', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    }

  },
}
