<template>
  <b-sidebar id="add-new-type-sidebar" :visible="isAddNewTypeSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-type-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ button_text }}產品類型</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- Body -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay :show='loading' spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- 產品類型-->
            <validation-provider #default="validationContext" name="產品類型" rules="required">
              <b-form-group label="產品類型" label-for="name">
                <b-form-input id="name" v-model="typeData.name" autofocus
                  :state="getValidationState(validationContext)" trim placeholder="產品類型" />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group label="描述" label-for="description">
              <b-form-textarea id="description" v-model="typeData.description" autofocus
                 trim placeholder="描述" />
            </b-form-group>

            <b-form-group label="排序" label-for="sort_order">
              <b-form-input id="sort_order" v-model="typeData.sort_order" autofocus type="number"
                 trim placeholder="排序" />
            </b-form-group>
            <!-- Is Active -->
            <b-form-group>
              <b-form-checkbox checked="true" name="check-button" switch inline v-model="typeData.is_active">
                {{ (typeData.is_active == true) ? '啟用' : '禁用' }}
              </b-form-checkbox>
            </b-form-group>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                {{ button_text }}
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                @click="hide">
                Cancel
              </b-button>
            </div>

          </b-form>
        </b-overlay>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js"
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewTypeSidebarActive',
    event: 'update:is-add-new-type-sidebar-active',
  },
  props: {
    isAddNewTypeSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      max,
    }
  },
  setup(props, { emit }) {

    const loading = ref(false)

    const blankTypeData = {
      id: 0,
      name: '',
      description: '',
      sort_order: 0,
      is_active: true,
    }
    const button_text = ref('新增')

    const typeData = ref(JSON.parse(JSON.stringify(blankTypeData)))

    const resettypeData = () => {
      typeData.value = JSON.parse(JSON.stringify(blankTypeData))
    }

    const toast = useToast()

    watch(() => [props.id, props.isAddNewTypeSidebarActive], () => {
      if (props.id > 0 && props.isAddNewTypeSidebarActive) {
        typeData.value.id = props.data.id
        typeData.value.code_name = props.data.code_name
        typeData.value.is_active = props.data.is_active == 1 ? true : false
        if (typeData.value.id > 0) {
          button_text.value = '修改'
        } else {
          button_text.value = '新增'
        }
      } else {
        button_text.value = '新增'
      }


    })

    const onSubmit = () => {
      loading.value = true
      store.dispatch("setting-type/addType", typeData.value)
        .then((response) => {
          loading.value = false
          emit("update:is-add-new-type-sidebar-active", false);
          emit('refetch-data')
          toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              text: response.data.message,
              icon: 'CheckIcon',
              variant: 'success'
            }
          })
        })
        .catch((error) => {
          loading.value = false
          toast({
            component: ToastificationContent,
            props: {
              title: 'Fail',
              text: error.response.data.message,
              icon: 'HeartIcon',
              variant: 'danger'
            }
          })
        })
    };

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resettypeData)

    return {
      typeData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      button_text,
      loading,
    };


  }

};
</script>

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-type-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>